import React, { useState } from "react";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  //timeout: 1000,
  headers: { "Authorization": `apikey ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/ld+json" }
});

export default function Appointment({ onAppointment, backgroundImage, buttonBackground }) {
  const [phone, setPhone] = useState("");
  const [valid, setValid] = useState(null);
  const handleSubmit = async(e) => {
    e.preventDefault();
    if (validate(phone)) {
      const {data: response} = await axiosInstance.get(`/people?phone=${phone}`)
      const people = response['hydra:member'];
      if (people && people.length > 0) {
        onAppointment(people[0]);
      } else {
        alert('Pas de de personne insris avec ce numéro de téléphone.')
      }
    }
  }

  const handleChange = ({ target })  =>{
    validate(target.value);
  }

  const validate = (phone) => {
    setPhone(phone);
    const reg = new RegExp('^[0-9]+$');
    if (!reg.test(phone)) {
      setValid(false);
      return false;
    }
    if (phone.length !== 10) {
      setValid(false);
      return false;
    }
    setValid(true);
    return true;
  }

  return (
    <div className={"appointment"}
         style={{ backgroundImage: backgroundImage ? `url(${backgroundImage.contentUrl})` : "none" }}>
      <form className="form" noValidate={false}>
        <h1>J'AI UN RDV</h1>
        <div className="form-group">
          <input placeholder="Tél."
                 className="form-control"
                 type="tel"
                 value={phone}
                 onChange={handleChange} />
        </div>
        {valid === false && <p className="error">Merci de saisir un numéro de téléphone valid.</p>}
        <button type="submit" className="submit" onClick={handleSubmit}
                style={{ backgroundImage: buttonBackground ? `url(${buttonBackground.contentUrl})` : "none" }}>
          Suivant
        </button>
      </form>
    </div>
  );
}
