import React, { Component } from "react";
import "./App.css";
import Form from "./components/Form";
import Scan from "./components/Scan";
import axios from "axios";
import Message from "./components/Message";
import Success from "./components/Success";
import TryMyChance from "./components/TryMyChance";
import CheckOut from "./components/CheckOut";
import nl2br from "./util";
import Appointment from "./components/appointment";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  //timeout: 1000,
  headers: { "Authorization": `apikey ${process.env.REACT_APP_API_KEY}`, "Content-Type": "application/ld+json" }
});
const APP_ROLE_CHECK_OUT = "APP_ROLE_CHECK_OUT";
const APP_ROLE_CHECK_IN = "APP_ROLE_CHECK_IN";

class App extends Component {
  state = {
    mercureUrlHub: process.env.REACT_APP_MERCURE_HUB_URL,
    deviceUID: window.location.pathname.slice(1),
    device: {},
    config: {},
    formConfig: null,
    person: null,
    isFormCompleted: false,
    isPersonRegistered: false,
    form: {},
    message: "",
    isUpdate: false,
    submit: true,
    scanRequired: false,
    appRole: null,
    checkOutCompleted: false,
    checkOutScanCompleted: false,
    checkOutPerson: null,
    checkOutForm: null,
    vehicleBrands: [],
    vehicleModels: [],
    appointment: false
  };

  getConfig = () => {
    const { deviceUID } = this.state;
    if (deviceUID) {
      axiosInstance.get("/devices/" + deviceUID).then(async response => {
        if (response) {
          const device = response.data;
          if (device.deviceCircle != null) {
            if (typeof device.deviceCircle !== "object") {
              try {
                device.deviceCircle = (await axiosInstance.get(device.deviceCircle)).data;
              } catch (e) {
              }
            }
            this.setState({ device });
            for (const d of device.deviceCircle.devices) {
              if (typeof d === "object" && (d.type.slug === "nfc-reader" || d.type.slug === "nfc-activator")) {
                this.handleMercury(d.deviceUID);
              }
            }
          }
          if (device.checkInConfig === null) return;
          axiosInstance.get(device.checkInConfig).then(async response => {
            const config = response.data;
            const imagesProperties = ["closeButtonIcon", "footerBackground", "mainBackground", "appRoleBackground", "headerBackground", "scanIcon", "successIcon", "checkInIcon", "appointmentButtonIcon", "formBackground", "backButtonIcon", "submitButtonBackground"];
            const urlSearchParams = new URLSearchParams();
            for (const imgProperty of imagesProperties) {
              if (typeof config[imgProperty] !== "undefined" && config[imgProperty] !== null) {
                urlSearchParams.append("id[]", config[imgProperty]);
              }
            }
            const configImages = (await axiosInstance.get("/images?" + urlSearchParams.toString())).data["hydra:member"];
            for (const configImage of configImages) {
              for (const imgProperty of imagesProperties) {
                if (config[imgProperty] === configImage["@id"]) {
                  config[imgProperty] = configImage;
                  break;
                }
              }
            }
            this.setState({ config: config, scanRequired: config.scanRequired });
            if (config.form || config.checkOutForm) {
              this.loadFormConfig();
            }
            if (config.requireCurrentVehicleModel === true) {
              this.loadVehicleBrands();
            }
            let css = config.cssStyle,
              head =
                document.head || document.getElementsByTagName("head")[0],
              style = document.createElement("style");
            head.appendChild(style);

            style.type = "text/css";
            if (style.styleSheet) {
              // This is required for IE8 and below.
              style.styleSheet.cssText = css;
            } else {
              style.appendChild(document.createTextNode(css));
            }
          });
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      this.setState({ message: "no deviceUID", showMessage: true });
    }
  };

  handleMercury = deviceUID => {
    let { mercureUrlHub } = this.state;
    if (deviceUID) {
      const mercureUrl = new URL(mercureUrlHub);
      mercureUrl.searchParams.append(
        "topic",
        "device/" + deviceUID + "/NFC_READ"
      );
      const eventSource = new EventSource(mercureUrl);
      // The callback will be called every time an update is published
      eventSource.onmessage = async (e) => {
        console.log(e);
        // do something with the payload
        if (e) {
          const payload = JSON.parse(e.data);
          if (
            payload.data.uid !== null &&
            typeof payload.data.uid !== "undefined"
          ) {
            this.handleScan(payload.data.uid);
          }
        }
      };
    } else {
      console.log("no deviceUID  provided");
    }
  };

  submitCheckOutAction = async (person, feedback = null) => {
    const { device } = this.state;
    const checkOutAction = { person: person["@id"], device: device["@id"], project: device.project, feedback };
    const coRes = await axiosInstance({
      method: "POST",
      url: "/check_out_actions",
      data: JSON.stringify(checkOutAction)
    });
    if (coRes.status === 201) {
      this.setState({ checkOutCompleted: true });
      setTimeout(() => this.reset(), 5000);
    }
  };

  handleCheckOutFeedback = async (feedback) => {
    this.submitCheckOutAction(this.state.checkOutPerson, feedback);
  };

  handleScan = async (code) => {
    const { config, device, appRole } = this.state;
    if (config.checkOutEnabled === true && appRole === APP_ROLE_CHECK_OUT) {
      if (typeof config.checkOutForm !== "undefined" && config.checkOutForm !== null) {
        const res = await axiosInstance("/people/" + code + "/" + device.project.match(/\d+/)[0]);
        if (res.status === 404) {
          alert("La carte est vide!");
        } else {
          const person = res.data;
          this.setState({ checkOutScanCompleted: true, checkOutPerson: person });
        }
      } else {
        const res = await axiosInstance("/people/" + code + "/" + device.project.match(/\d+/)[0]);
        if (res.status === 404) {
          alert("La carte est vide!");
        } else {
          const person = res.data;
          this.submitCheckOutAction(person);
        }
      }
    } else if (config.hasInvitedPeople === true) {
      let person;
      const res = await axiosInstance("/people/" + code + "/" + device.project.match(/\d+/)[0]);
      person = res.data;

      if (person && config.requireFormReview) {
        this.setState({ person: person });
      } else {
        const checkInAction = { person: person["@id"], project: device.project };
        const res = await axiosInstance({
          method: "POST",
          url: "/check_in_actions",
          data: JSON.stringify(checkInAction)
        });
        if (res.status === 201) {
          this.setState({ isPersonRegistered: true, person: person });
        }
      }
    } else if (config.checkOutEnabled !== true || appRole === APP_ROLE_CHECK_IN) {
      const { form, isUpdate, nameId } = this.state;
      this.onSubmit(code, form, isUpdate, nameId);
    } else if (this.state.config.appointmentEnabled) {
      const { form, isUpdate, nameId } = this.state;
      this.onSubmit(code, form, isUpdate, nameId);
    }
  };

  loadFormConfig = () => {
    let { config } = this.state;
    if (config.form) {
      axiosInstance.get(config.form).then(({ data: formConfig }) => {
        this.setState({
          formConfig
        });
      });
    }
    if (config.checkOutForm) {
      axiosInstance.get(config.checkOutForm).then(({ data: checkOutForm }) => {
        this.setState({
          checkOutForm
        });
      });
    }
  };

  onSubmit = async (uid, form, isUpdate, nameId, upToDateIsFormCompleted) => {
    const { isFormCompleted } = this.state;
    if (isFormCompleted !== true && upToDateIsFormCompleted !== true) {
      return;
    }
    const { device, submit, person } = this.state;
    if (submit) {
      if (form !== null) {
        form.uid = uid;
        form.project = device.project;
        if (!isUpdate) {
          axiosInstance({
            method: "post",
            url: "/people",
            data: form
          }).then(({ data: person }) => {
            this.setState({ submit: false, isPersonRegistered: true, person: person });
            this.submitCheckInAction(person, device);
          });
          const { config, person } = this.state;
          if (config.hasInvitedPeople === true) {
            if (await this.submitCheckInAction(person, device)) {
              this.setState({ isPersonRegistered: true, person: person });
            }
          }
        } else {
          form.id = nameId;
          if (typeof form.uid === "undefined" || form.uid === null) {
            form.uid = person.uid;
          }
          axiosInstance({
            method: "put",
            url: "/people/" + nameId,
            data: form
          }).then(({ data: person }) => {
            this.setState({ submit: false, isPersonRegistered: true, person: person });
          });
        }
      }
    }
  };

  submitCheckInAction = async (person, device) => {
    const checkInAction = { person: person["@id"], project: device.project, device: device["@id"] };
    const res = await axiosInstance("/check_in_actions", {
      method: "POST",
      headers: new Headers({ "content-type": "application/ld+json" }),
      data: JSON.stringify(checkInAction)
    });
    return res.status === 201;
  };

  nextStep = obj => {
    this.setState({
      isFormCompleted: true,
      isUpdate: obj.isUpdate,
      nameId: obj.nameId
    });
    const { config, scanRequired } = this.state;
    if ((scanRequired === false) || (config.hasInvitedPeople === true && config.requireFormReview === true)) {
      this.onSubmit(null, this.state.form, obj.isUpdate, obj.nameId, true);
    }
  };

  loadVehicleBrands = () => {
    axiosInstance.get("/vehicle_brands?itemsPerPage=1000").then(response => {
      if (response) {
        this.setState({
          vehicleBrands: [
            ...(response.data["hydra:member"]),
            {
              "@id": -2,
              id: -2,
              name: "Autre"
            },
            {
              "@id": -3,
              id: -3,
              name: "Je n'ai aucune voiture"
            }
          ]
        });
      }
    });
  };

  resetVehicleModels = () => {
    this.setState({ vehicleModels: [] });
  };

  loadVehicleModels = (brandIRI) => {
    axiosInstance.get("/vehicle_models?itemsPerPage=1000&brand=" + brandIRI).then(response => {
      if (response) {
        this.setState({
          vehicleModels: [
            ...(response.data["hydra:member"]),
            {
              "@id": -2,
              id: -2,
              name: "Autre"
            }]
        });
      }
    });
  };

  reset = () => {
    this.setState({
      isFormCompleted: false,
      isPersonRegistered: false,
      isUpdate: false,
      person: null,
      form: {},
      message: "",
      showMessage: false,
      submit: true,
      appRole: null,
      checkOutCompleted: false,
      checkOutScanCompleted: false,
      checkOutPerson: null
    });
  };

  componentDidMount = () => {
    this.getConfig();
  };

  addFormItems = (f) => {
    const { form } = this.state;
    for (const [key, value] of Object.entries(f)) {
      form[key] = value;
    }
    this.setState({ form });
  };

  setPerson = (person) => {
    this.setState({ person });
  };

  handleAppointment = async (person) => {
    if (person) {
      this.setState({person, appointment: false, isFormCompleted: true});
    }
  };

  render() {
    let {
      config,
      formConfig,
      isFormCompleted,
      isPersonRegistered,
      message,
      showMessage,
      scanRequired,
      device,
      person,
      form,
      appRole,
      checkOutCompleted,
      checkOutScanCompleted,
      checkOutForm,
      vehicleBrands,
      vehicleModels,
      appointment
    } = this.state;
    const renderForm = formConfig !== null && isFormCompleted === false;
    let mainBg = "none";
    if (config.mainBackground && ((config.checkOutEnabled && !appRole && !appointment && !person) || config.checkOutEnabled !== true)) {
      mainBg = `url(${config.mainBackground.contentUrl})`;
    }

    return (
      <div
        className="App"
        style={{
          backgroundColor: config.mainBackgroundColor,
          backgroundImage: mainBg,
          color: config.mainColor
        }}
      >
        <header>
          {(appRole !== null || appointment) && config.headerBackground &&
          <img src={config.headerBackground.contentUrl} alt={""}/>
          }
          <div className="row">
            {(appRole !== null || appointment) &&
            <div className="col-3">
              <button className={"back-button"}
                      onClick={() => this.reset()}>
                <span className="icon"/>
                {config.closeButtonLabel ? config.closeButtonLabel : "BACK"}
              </button>
            </div>
            }
            <div className="col">
              {(appRole === APP_ROLE_CHECK_IN || appointment) && config.checkInHeaderTitle && (
                <h1 className={"header-title"}>{config.checkInHeaderTitle}</h1>
              )}
            </div>
            <div className="col-2"/>
          </div>
        </header>
        {config.checkOutEnabled && !appRole && !appointment && !isFormCompleted && (
          <div className={"app-role"}>
            <div className="row no-gutters">
              <div className="col-8">
                {config.appRoleTitle &&
                <div className={"app-role-title"} style={{ "color": config.appRoleColor }}
                     dangerouslySetInnerHTML={{ __html: nl2br(config.appRoleTitle) }}/>}
                {config.appRoleSubtitle && <div className={"app-role-subtitle"}>{config.appRoleSubtitle}</div>}
              </div>
              <div className="col-4">
                <div className="row">
                  <div className="col-12">
                    <button
                      onClick={() => this.setState({ appRole: APP_ROLE_CHECK_IN })}
                      className={"checkin-btn float-right"}
                      style={{ backgroundImage: "url(" + (config.checkInIcon ? config.checkInIcon.contentUrl : "/img/checkin.png") + ")" }}/>
                  </div>
                  <div className="col-12">
                    <button
                      onClick={() => this.setState({ appRole: APP_ROLE_CHECK_OUT })}
                      className={"checkout-btn float-right py-5"}>
                      <span/>{config.checkOutButtonLabel ? config.checkOutButtonLabel : "Check Out"}</button>
                  </div>
                </div>
              </div>
            </div>
            {config.appointmentEnabled === true &&
            <button className={"appointment-btn"}
                    style={{ backgroundImage: config.appointmentButtonIcon ? `url(${config.appointmentButtonIcon.contentUrl})` : "none" }}
                    onClick={() => this.setState({ appointment: true })}/>}
          </div>
        )}
        {renderForm === true && (config.checkOutEnabled !== true || (appRole === APP_ROLE_CHECK_IN && config.checkOutEnabled === true)) && (
          <Form
            loadVehicleModels={this.loadVehicleModels}
            resetVehicleModels={this.resetVehicleModels}
            addFormItems={this.addFormItems}
            moveToNextStep={this.nextStep}
            config={config}
            person={person}
            form={form}
            formConfig={formConfig !== null ? formConfig : ""}
            vehicleBrands={vehicleBrands}
            vehicleModels={vehicleModels}
            setPerson={this.setPerson}
            style={{
              btnBgColor: config.buttonBgColor,
              btnTextColor: config.buttonColor,
              backgroundImage: config.formBackground ? `url(${config.formBackground.contentUrl})` : "none",
              zIndex: 10
            }}
          />
        )}
        {appRole === APP_ROLE_CHECK_OUT && config.checkOutEnabled === true && (
          <div className={"checkout"}>
            <CheckOut
              style={{
                scanMessage: nl2br(config.checkOutScanMessage),
                scanIcon: config.scanIcon,
                buttonColor: config.buttonColor,
                buttonBackgroundColor: config.buttonBgColor,
                successIcon: config.successIcon,
                checkOutSuccessMessage: nl2br(config.checkOutSuccessMessage)
              }}
              handleCheckOutFeedback={this.handleCheckOutFeedback}
              checkOutForm={checkOutForm}
              checkOutCompleted={checkOutCompleted}
              checkOutScanCompleted={checkOutScanCompleted}
              scanType={config.scanType}
              project={device.project}
              handleScan={this.handleScan}
            />
          </div>
          )}
          {((isFormCompleted && scanRequired) /*|| config.hasInvitedPeople === true*/) && isPersonRegistered === false && (
          <Scan
            person={person}
            style={{
              scanMessage: nl2br(config.scanMessage),
              scanIcon: config.scanIcon,
              buttonColor: config.buttonColor,
              buttonBackgroundColor: config.buttonBgColor,
              backgroundImage: config.formBackground ? `url(${config.formBackground.contentUrl})` : "none"
            }}
            scanType={config.scanType}
            project={device.project}
            handleScan={this.handleScan}
          />
        )}
        {config.hasGame === true && isFormCompleted && (
          <TryMyChance
            device={device}
            person={person}
            config={config}
            reset={this.reset}
          />
        )}
        {isPersonRegistered && person !== null && config.hasGame !== true && (
          <Success
            hasInvitedPeople={config.hasInvitedPeople}
            successIcon={config.successIcon}
            successTitle={nl2br(config.successTitle)}
            successSubTitle={nl2br(config.successSubTitle)}
            person={person}
            reset={this.reset}
            backgroundImage={config.formBackground ? `url(${config.formBackground.contentUrl})` : "none"}
          />
        )}
        {appointment &&
        <Appointment onAppointment={this.handleAppointment}
                     buttonBackground={config.submitButtonBackground}
                     backgroundImage={config.formBackground}/>}
        {message !== "" && showMessage && <Message message={message}/>}
        {appRole !== null && config.footerBackground &&
        <footer id={"footer"} style={{ backgroundImage: `url(${config.footerBackground.contentUrl})` }}>
        </footer>}
      </div>
    );
  }
}

export default App;
