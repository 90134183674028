import React, { Component } from "react";
import ScanQRCode from "./ScanQRCode";
import EnterCode from "./EnterCode";

class Scan extends Component {
  state = {
    codeMethod: null
  };

  back = () => {
    this.setState({ codeMethod: null });
  };

  render() {
    const { scanIcon, scanMessage, buttonColor, buttonBackgroundColor, backgroundImage } = this.props.style;
    const { scanType, person } = this.props;
    const { codeMethod } = this.state;
    if (scanType === "qrcode") {
      return (
        <div className="scan" style={{backgroundImage: backgroundImage}}>
          {codeMethod === "scan_qrcode" &&
          <ScanQRCode handleScan={this.props.handleScan} back={this.back} style={{
            buttonColor: buttonColor,
            buttonBackgroundColor: buttonBackgroundColor
          }}/>
          }
          {codeMethod === "enter_code" &&
          <EnterCode handleScan={this.props.handleScan} back={this.back} style={{
            buttonColor: buttonColor,
            buttonBackgroundColor: buttonBackgroundColor
          }}/>
          }
          {codeMethod === null &&
          <div>
            <button onClick={() => this.setState({ codeMethod: "scan_qrcode" })}
                    style={{ "color": buttonColor, "backgroundColor": buttonBackgroundColor }}>Scan QRCode
            </button>
            <button onClick={() => this.setState({ codeMethod: "enter_code" })}
                    style={{ "color": buttonColor, "backgroundColor": buttonBackgroundColor }}>Enter QRCode
            </button>
          </div>
          }
        </div>
      );
    } else {
      return (
        <div className="scan" style={{backgroundImage: backgroundImage}}>
          <div>
            {person && <p className="welcome-msg">{`Bonjour ${person.fullName}`}</p>}
            <img
              style={{ marginBottom: "10px" }}
              src={scanIcon ? scanIcon.contentUrl : null}
              alt="Scan your card"
            />
            <p dangerouslySetInnerHTML={{ __html: scanMessage }}/>
          </div>
        </div>
      );
    }
  }
}

export default Scan;
