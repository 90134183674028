import React, { Component } from "react";

class Success extends Component {
  render() {
    const { hasInvitedPeople, person, successIcon, successTitle, successSubTitle, backgroundImage } = this.props;
    let successMsg = successTitle;
    if (hasInvitedPeople) {
      successMsg = successMsg.replace("{name}", typeof person.profile.fullname !== "undefined" ? person.profile.fullname : person.profile.lastname + " " + person.profile.firstname);
    }
    setTimeout(() => {
        if (typeof this.props.reset !== "undefined") {
          this.props.reset();
        }
      }
      , 10000);
    return (
      <div className={"success"} style={{backgroundImage}}>
        <div>
          {typeof successMsg !== "undefined" && <h2 dangerouslySetInnerHTML={{ __html: successMsg }}/>}
          {typeof successSubTitle !== "undefined" &&
          <p style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: successSubTitle }}/>}
          {typeof successIcon !== "undefined" && successIcon !== null &&
          <img src={successIcon.contentUrl} alt="success icon"/>}
        </div>
      </div>
    );
  }
}

export default Success;
