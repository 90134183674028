import React, { Component, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const INVALID_VALUE = 'invalid_value';
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  //timeout: 1000,
  headers: { "Authorization": `apikey ${process.env.REACT_APP_API_KEY}` }
});

let timer;

class FormStep extends Component {
  state = {
    formItemsArray: [],
    form: {},
    isDisabled: true,
    formLength: 0,
    shouldGoNextStep: true,
    existedName: false,
    existedNamesArray: [],
    isUpdate: false,
    nameId: "",
    optIn: true,
    flashMessages: [],
    cities: [],
  };

  constructor(props) {
    super(props);

    this.currentVehicleModelInput = React.createRef();
    this.domForm = React.createRef();
  }

  componentDidMount() {
    const { config } = this.props;
    if (typeof this.props.formStep !== "undefined") {
      this.setState({ formLength: config.requireCurrentVehicleModel === true ? this.props.formStep.formItems.length + 2 : this.props.formStep.formItems.length });
      this.handleInputBackGroundColorAndFooterOnFocusAndOnBlur();
      const selectEls = document.querySelectorAll(".form select");
      const form = {};
      for (const formItem of this.props.formStep.formItems) {
        form[formItem.name] = formItem.value;
        if (formItem.name === 'optin') {
          form[formItem.name] = formItem.selected === true;
        }
        if (formItem.type === 'city') {
          // load cities
          axiosInstance.get('/cities?itemsPerPage=400').then(({ data: response }) => this.setState({cities: response['hydra:member']}));
        }
      }
      for (const selectEl of selectEls) {
        form[selectEl.name] = selectEl.value;
      }
      this.setState({ form: form });

      let formItemsArray = [];
      const { formStep } = this.props;
      if (typeof formStep !== "undefined" && typeof formStep.formItems !== "undefined") {
        formItemsArray = formStep.formItems.sort((a, b) =>
          a.order > b.order
            ? 1
            : a.order === b.order
            ? a.id > b.id
              ? 1
              : -1
            : -1
        );
      }
      this.setState({ formItemsArray: formItemsArray });
      const { optIn } = this.state;
      for(let formItem of formItemsArray) {
        if (formItem.name === "optin" && formItem.type === "checkbox") {
          form[formItem.name] = optIn ? formItem.value : null;
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.formStep !== prevProps.formStep) {
      this.setState({ formLength: this.props.formStep.formItems.length });
      this.handleInputBackGroundColorAndFooterOnFocusAndOnBlur();
      const selectEls = document.querySelectorAll(".form select");
      const form = {};
      for (const formItem of this.props.formStep.formItems) {
        form[formItem.name] = "";
      }
      for (const selectEl of selectEls) {
        form[selectEl.name] = selectEl.value;
      }
      this.setState({ form: form });
    }
  }

  getInputValueFromPerson(formItemName) {
    const { person } = this.props;
    const { form } = this.state;
    if (typeof form[formItemName] !== "undefined" && form[formItemName] !== null) {
      return form[formItemName];
    } else if (person !== null && typeof person[formItemName] !== "undefined" && person[formItemName] !== null) {
      return person[formItemName];
    } else if (person !== null && person.profile !== null && typeof person.profile[formItemName] !== "undefined" && person.profile[formItemName] !== null) {
      return person.profile[formItemName];
    }
  }

  handleInputBackGroundColorAndFooterOnFocusAndOnBlur = () => {
    const { config } = this.props;
    var elements = document.querySelectorAll("input");
    for (const element of elements) {
      element.style.backgroundColor = config.inputBackgroundColor;
      element.addEventListener("focus", evt => {
        evt.target.style.backgroundColor = config.inputFocusBackgroundColor;
        document.getElementById("footer").style.display = "none";
      });
      element.addEventListener("blur", evt => {
        evt.target.style.backgroundColor = config.inputBackgroundColor;
        document.getElementById("footer").style.display = "block";
      });
    }
  };

  fetchExistedNames = name => {
    const { config, formConfig } = this.props;
    const project = formConfig.project;
    const lastSlashIndex = project.lastIndexOf("/");
    const projectId = project.substring(lastSlashIndex + 1);
    if (config.searchEnabled) {
      if (name.length > 0) {
        axiosInstance.get(
          `/people/find?project=${projectId}&fullname=${name}`
        ).then(res => {
          if (res) {
            this.setState({ existedName: true, existedNamesArray: res.data });
          }
        });
      } else {
        this.setState({ existedNamesArray: [] });
      }
    }
  };

  selectedName = (name, id, email) => {
    const { form } = this.state;
    const nameInput = document.querySelectorAll("input")[0];
    const emailInput = document.querySelectorAll("input")[1];
    const formName = nameInput.name;
    const formEmail = emailInput.name;
    this.setState({ isDisabled: false });
    nameInput.value = name;
    emailInput.value = email;
    form[formName] = name;
    form[formEmail] = email;
    this.setState({ nameId: id, existedName: false, isUpdate: true });
    clearTimeout(timer);
  };

  inputChangeHandler = async ({ target: formInput }, formItem) => {
    const flashMessages = [];
    let { form } = this.state;
    if (formInput.type === "checkbox") {
      form[formInput.name] = formInput.checked;
    } else {
      form[formInput.name] = formInput.value;
      if (formItem && formItem.unique === true) {
        if (formInput.value.length > 1) {
          const { config, formConfig } = this.props;
          const {data: people} = await axiosInstance.get(
            `/people?project=${config.project}&${formItem.name}=${formInput.value}`
          );
          if (people && people['hydra:member'] && people['hydra:member'].length > 0) {
            const person = people['hydra:member'][0];
            flashMessages.push({type: 'info', value: `Votre Compte est reconnu automatiquement en se basant sur votre ${formItem.label}`});
            this.props.setPerson(person);
            for (const fi of formConfig.formItems) {
              if (person[fi.name]) {
                form[fi.name] = person[fi.name];
              }
            }
          }
        }
      }
    }
    if (formInput.name === "currentVehicleBrand") {
      if (formInput.value !== -1) {
        this.props.loadVehicleModels(formInput.value);
      } else {
        this.currentVehicleModelInput.current.value = -1;
        this.props.resetVehicleModels();
      }
    }
    this.setState({ message: "", form: form, flashMessages });
  };

  validateForm = () => {
    let isValid = true;
    const form = this.domForm.current;
    this.removeElementsByClass(form, "error");
    for (let i = 0; i < form.elements.length; i++) {
      const input = form.elements[i];
      if (
        input.type.toLowerCase() === "email" ||
        input.type.toLowerCase() === "text" ||
        input.type.toLowerCase() === "tel"
      ) {
        if (input.checkValidity()) {
          input.className = "form-control";
          if (input.type.toLowerCase() === "email" && input.value !== '') {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(input.value).toLowerCase())) {
              isValid = false;;

              this.setState({ isDisabled: true });
              input.className = "form-control is-invalid";
              const errorSpan = document.createElement("span");
              errorSpan.setAttribute("class", "error");
              errorSpan.innerHTML = "Veuillez saisir un email valide";
              this.insertAfter(input, errorSpan);
            }
          } else if (input.type.toLowerCase() === "tel" && input.value !== '') {
            const re = /^(\+)?\d{10,15}$/;
            if (!re.test(String(input.value).toLowerCase())) {
              isValid = false;
              this.setState({ isDisabled: true });
              input.className = "form-control is-invalid";
              const errorSpan = document.createElement("span");
              errorSpan.setAttribute("class", "error");
              errorSpan.innerHTML = "Veuillez saisir un numéro de téléphone valide";
              this.insertAfter(input, errorSpan);
            }
          }
        } else if (!input.checkValidity()) {
          isValid = false;
          this.setState({ isDisabled: true });
          input.className = "form-control is-invalid";
          const errorSpan = document.createElement("span");
          errorSpan.setAttribute("class", "error");
          errorSpan.innerHTML = input.validationMessage;
          this.insertAfter(input, errorSpan);
        }
      } else if(input.nodeName === 'SELECT' && input.value === INVALID_VALUE) {
        isValid = false;
        this.setState({ isDisabled: true });
        input.className = "form-control is-invalid";
        const errorSpan = document.createElement("span");
        errorSpan.setAttribute("class", "error");
        errorSpan.innerHTML = 'Invalid';
        this.insertAfter(input, errorSpan);
      } else if(input.checkValidity()) {
        input.className = "form-control";
      }
    }
    return isValid;
  };

  removeElementsByClass = (parent, className) => {
    const elements = parent.getElementsByClassName(className);
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  getForm = () => {
    const { form } = this.state;
    return form;
  };

  reset = () => {
    const { form } = this.state;
    for (const formItem of this.props.formStep.formItems) {
      form[formItem.name] = "";
    }
    this.setState({ form });
  };

  insertAfter = (referenceNode, newNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  };

  removeElement = element => {
    element.parentNode.removeChild(element);
  };

  render() {
    const { formStep, config, vehicleBrands, vehicleModels } = this.props;
    const { formItemsArray, form, existedName, existedNamesArray, optIn, flashMessages, cities } = this.state;
    return (
      <Fragment>
        <div className="flash-messages">
          {flashMessages.map((message, index) => <p key={index} className={message.type}>{message.value}</p>)}
        </div>
        <form ref={this.domForm} className={"form-step"}>
          {typeof formStep !== "undefined" && formStep.formItems !== "undefined" && (
            <div style={{ margin: "0 auto" }}>
              {formItemsArray.map(formItem => {
                if (formItem.type === "textarea") {
                  return (
                    <div className={"form-group inputs"} key={formItem["@id"]}>
                      <textarea
                        key={formItem.id}
                        name={formItem.name}
                        required={formItem.required}
                        placeholder={formItem.placeHolder}
                        id={formItem.slug}
                        onChange={(e) => this.inputChangeHandler(e, formItem)}
                        className={"form-control"}
                        style={{
                          borderRadius: config.inputBorderRadius
                        }}
                      />
                    </div>
                  );
                }
                else if (formItem.type === "choice") {
                  return (
                    <div className={"form-group inputs"} key={formItem["@id"]}>
                      <select
                        key={formItem.id}
                        name={formItem.name}
                        required={formItem.required}
                        placeholder={formItem.placeHolder}
                        id={formItem.slug}
                        onChange={this.inputChangeHandler}
                        className={"form-control"}
                        style={{
                          borderRadius: config.inputBorderRadius
                        }}
                      >
                        <option value={INVALID_VALUE} selected="selected">{formItem.label}</option>
                        {formItem.formChoices.map(choice => {
                          return (
                            <option value={choice.value} key={choice.id}>
                              {choice.key}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                }
                else if (formItem.type === "city") {
                  return (
                    <div className={"form-group inputs"} key={formItem["@id"]}>
                      <select
                        key={formItem.id}
                        name={formItem.name}
                        required={formItem.required}
                        placeholder={formItem.placeHolder}
                        id={formItem.slug}
                        onChange={this.inputChangeHandler}
                        className={"form-control"}
                        style={{
                          borderRadius: config.inputBorderRadius
                        }}
                      >
                        <option value={INVALID_VALUE} selected="selected">{formItem.label}</option>
                        {cities && cities.map(city => {
                          return (
                            <option value={city['@id']} key={city.id}>
                              {city.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                }
                else if (formItem.name !== "optin") {
                  return (
                    <div className={"form-group inputs"} key={formItem["@id"]}>
                      <div className="row">
                        <div className="col">
                          <input
                            id={formItem.slug}
                            type={formItem.type}
                            name={formItem.name}
                            value={this.getInputValueFromPerson(formItem.name)}
                            required={formItem.required}
                            disabled={!formItem.enabled}
                            placeholder={formItem.placeHolder}
                            onChange={(e) => this.inputChangeHandler(e, formItem)}
                            onBlur={() => this.setState({ existedName: false })}
                            onFocus={() => {
                              if (formItem.type === "text") {
                                this.setState({ existedName: true });
                              }
                            }}
                            className={"form-control"}
                            style={{ borderRadius: config.inputBorderRadius }}
                            minLength={formItem.minLength ? formItem.minLength : ""}
                            maxLength={formItem.maxLength ? formItem.maxLength : ""}
                          />
                        </div>
                        {formItem.type === "checkbox" &&
                        <div className="col">
                          <label htmlFor={formItem.slug} dangerouslySetInnerHTML={{ __html: formItem.label }}/>
                        </div>
                        }
                      </div>
                      {formItem.type === "text" &&
                      config.searchEnabled &&
                      existedName && (
                        <ul className="suggestedList">
                          {existedNamesArray.map(({ profile, id, email }) => {
                            const fullname = profile.fullname;
                            return (
                              <li
                                key={id}
                                onMouseDown={() =>
                                  this.selectedName(fullname, id, email)
                                }
                              >
                                {fullname}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {config.requireCurrentVehicleModel === true && (
                <div>
                  <label style={{ color: "grey", fontSize: "20px" }}>Voiture actuelle</label>
                  <div className={"form-group inputs"} key={"currentVehicleBrand-xx"}>
                    <select
                      name={"currentVehicleBrand"}
                      required={false}
                      id={"currentVehicleBrand"}
                      onChange={this.inputChangeHandler}
                      className={"form-control"}
                      style={{
                        borderRadius: config.inputBorderRadius
                      }}
                    >
                      {typeof config.currentVehicleBrandFormEmptyValue !== "undefined" && config.currentVehicleBrandFormEmptyValue !== null && (
                        <option value={-1}>
                          {config.currentVehicleBrandFormEmptyValue}
                        </option>
                      )}
                      {Array.isArray(vehicleBrands) && vehicleBrands.map(choice => {
                        return (
                          <option value={choice["@id"]} key={choice.id}>
                            {choice.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {(form["currentVehicleBrand"] !== "-2" && form["currentVehicleBrand"] !== "-3") &&
                  <div className={"form-group inputs"} key={"currentVehicleModel-xxy"}>
                    <select
                      name={"currentVehicleModel"}
                      required={false}
                      id={"currentVehicleModel"}
                      onChange={this.inputChangeHandler}
                      className={"form-control"}
                      style={{
                        borderRadius: config.inputBorderRadius
                      }}
                      ref={this.currentVehicleModelInput}
                    >
                      {typeof config.currentVehicleModelFormEmptyValue !== "undefined" && config.currentVehicleModelFormEmptyValue !== null && (
                        <option value={-1}>
                          {config.currentVehicleModelFormEmptyValue}
                        </option>
                      )}
                      {Array.isArray(vehicleModels) && vehicleModels.map(choice => {
                        return (
                          <option value={choice["@id"]} key={choice.id}>
                            {choice.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  }
                  {form["currentVehicleBrand"] === "-2" &&
                  <div className={"form-group inputs"} key={"currentVehicleBrandOther-xxy"}>
                    <input
                      name={"currentVehicleBrandOther"}
                      type="text"
                      required={false}
                      onChange={this.inputChangeHandler}
                      className={"form-control"}
                      placeholder={"Saisir la marque"}
                      style={{
                        borderRadius: config.inputBorderRadius
                      }}/>
                  </div>
                  }
                  {(form["currentVehicleBrand"] === "-2" || form["currentVehicleModel"] === "-2") &&
                  <div className={"form-group inputs"} key={"currentVehicleModelOther-xxy"}>
                    <input
                      name={"currentVehicleModelOther"}
                      type="text"
                      required={false}
                      onChange={this.inputChangeHandler}
                      className={"form-control"}
                      placeholder={"Saisir le modele"}
                      style={{
                        borderRadius: config.inputBorderRadius
                      }}/>
                  </div>
                  }
                </div>
              )}

              {formItemsArray.map(formItem => {
                if (formItem.name === "optin" && formItem.type === "checkbox") {
                  return (
                    <div className={`form-group inputs ${formItem.name}`} key={formItem["@id"]}>
                      <div className="row">
                        <div className="col">
                          <input
                            id={formItem.slug}
                            type={formItem.type}
                            name={formItem.name}
                            checked={form[formItem.name]}
                            value={formItem.value}
                            required={formItem.required}
                            disabled={!formItem.enabled}
                            placeholder={formItem.placeHolder}
                            onChange={(e) => {
                              this.setState({ optIn: !optIn });
                              this.inputChangeHandler(e);
                            }}
                            className={"form-control"}
                            style={{
                              borderRadius: config.inputBorderRadius
                            }}
                          />
                        </div>
                        {formItem.type === "checkbox" &&
                        <div className="col">
                          <label htmlFor={formItem.slug} dangerouslySetInnerHTML={{ __html: formItem.label }}/>
                        </div>
                        }
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </form>
      </Fragment>
    );
  }
}

export default FormStep;
