import React, { Component } from "react";
import Scan from "./Scan";
import Success from "./Success";

class CheckOut extends Component {
  render() {
    const { scanIcon, scanMessage, buttonColor, buttonBackgroundColor, successIcon, checkOutSuccessMessage } = this.props.style;
    const { scanType, checkOutCompleted, checkOutScanCompleted, project, checkOutForm } = this.props;
    console.log("checkOutForm:", checkOutForm);
    return (
      <div>
        {checkOutCompleted === false && checkOutScanCompleted === false &&
        <Scan
          style={{
            scanMessage: scanMessage,
            scanIcon: scanIcon,
            buttonColor: buttonColor,
            buttonBackgroundColor: buttonBackgroundColor
          }}
          scanType={scanType}
          project={project}
          handleScan={this.handleScan}
        />
        }

        {checkOutCompleted !== true && checkOutForm && checkOutScanCompleted === true &&
        <div style={{textAlign: 'center'}}>
          <h1 className={"mb-5"}>{checkOutForm.title}</h1>
          <div className={"feedback-list"}>
            {checkOutForm.formItems.map(formItem => {
              if (formItem.type === "choice") {
                return (
                  <div className={"form-group inputs"} key={formItem["@id"]}>
                    {formItem.formChoices.map(choice => {
                      if (choice.image) {
                        return (
                          <img src={choice.image.contentUrl} data-value={choice.value} key={choice.id} onClick={()=>this.handleClick(formItem.name, choice.key)} alt={choice.key}/>
                        );
                      } else {
                        return (<div></div>)
                      }
                    })}
                  </div>
                );
              } else return (<div></div>);
            })}
          </div>
        </div>
        }
        {checkOutCompleted === true &&
        <Success
          successIcon={successIcon}
          successTitle={checkOutSuccessMessage ? checkOutSuccessMessage : "Thank you for your visit"}
        />
        }
      </div>
    );
  }

  handleClick(key, value) {
    const feedback = {};
    feedback[key] = value;
    this.props.handleCheckOutFeedback(feedback)
  }
}

export default CheckOut;
