import React, { Component } from "react";

class EnterCode extends Component {
  handleSubmit() {
    const code = document.getElementById("code").value;
    if (code.length > 0) {
      this.props.handleScan(code);
    }
  }

  handleInputChange() {
    const code = document.getElementById("code").value;
    if (code.length > 0) {
      this.setState({ showErrorMessage: false });
    } else {
      this.setState({ showErrorMessage: true });
    }
  }

  render() {
    const { buttonColor, buttonBackgroundColor } = this.props.style;
    return (
      <div>
        <label>
          Code:
          <input type="text" id={"code"} placeholder={"Code"} onChange={() => this.handleInputChange()}/>
        </label>
        <button onClick={() => this.handleSubmit()}
                style={{ "color": buttonColor, "backgroundColor": buttonBackgroundColor }}>Envoyer
        </button>
        <button onClick={() => this.props.back()}
                style={{ "color": buttonColor, "backgroundColor": buttonBackgroundColor }}>Retour
        </button>
      </div>
    );
  }
}

export default EnterCode;
