import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import FormStep from "./FormStep";

class Form extends Component {
  constructor(props) {
    super(props);

    this.formStep = React.createRef();
  }

  state = {
    isDisabled: true,
    formLength: this.props.formConfig.formItems.length,
    formSteps: [],
    shouldGoNextStep: true,
    existedName: false,
    existedNamesArray: [],
    isUpdate: false,
    nameId: "",
    currentStepIndex: 0,
    showLegalNotices: false
  };

  componentDidMount() {
    const { formConfig } = this.props;
    let formSteps = [];
    if (typeof formConfig.formSteps !== "undefined" && Array.isArray(formConfig.formSteps) && formConfig.formSteps.length > 0) {
      formSteps = formConfig.formSteps.sort((a, b) =>
        a.order > b.order
          ? 1
          : a.order === b.order
            ? a.id > b.id
              ? 1
              : -1
            : -1
      );
    } else {
      formSteps.push({ formItems: formConfig.formItems });
    }
    this.setState({ formSteps });
  }

  shouldGoNextStep = () => {
    if (this.formStep.current.validateForm(this.formStep.current)) {
      const { form } = this.props;
      for (const [key, value] of Object.entries(this.formStep.current.getForm())) {
        form[key] = value;
      }
      this.props.addFormItems(form);
      this.formStep.current.reset();
      const { currentStepIndex } = this.state;
      this.setState({ currentStepIndex: currentStepIndex + 1 });
    }
  };

  submit = () => {
    if (this.formStep.current.validateForm(this.formStep.current)) {
      const { form } = this.props;
      const stepForm = this.formStep.current.getForm();
      for (const [key, value] of Object.entries(stepForm)) {
        form[key] = value;
      }
      if (form.currentVehicleBrand === "-1" || form.currentVehicleBrand === "-2") {
        delete form.currentVehicleBrand;
      }
      if (form.currentVehicleBrand === "-3") {
        form.hasNoVehicle = true;
        delete form.currentVehicleBrand;
      }
      if (form.currentVehicleModel === "-1" || form.currentVehicleModel === "-2") {
        delete form.currentVehicleModel;
      }
      this.formStep.current.reset();

      const { person } = this.props;
      let { isUpdate, nameId } = this.state;
      if (person !== null && typeof person.id !== "undefined" && person.id !== null) {
        isUpdate = true;
        nameId = person.id;
      }
      this.props.addFormItems(form);
      this.props.moveToNextStep({
        isUpdate: isUpdate,
        nameId: nameId
      });
    }
  };

  setPerson = (person) => {
    this.props.setPerson(person);
  };

  render() {
    const { formConfig, style, config, person, vehicleBrands, vehicleModels } = this.props;
    const { currentStepIndex, existedNamesArray, existedName, formSteps, showLegalNotices } = this.state;
    return (
      <div className={"form"} style={{ backgroundImage: style.backgroundImage, backgroundRepeat: "no-repeat" }}>
        <h3
          style={{
            textAlign: "center",
            fontSize: "40px",
            margin: "0",
            color: config.titleColor
          }}
        >
          {formConfig.title}
        </h3>
        <h5
          style={{
            textAlign: "center",
            marginBottom: "50px",
            fontSize: "25px",
            color: config.subTitleColor
          }}
          dangerouslySetInnerHTML={{ __html: formConfig.subTitle }}
        />
        {formSteps.length > 0 && (
          <div>
            <FormStep
              loadVehicleModels={this.props.loadVehicleModels}
              resetVehicleModels={this.props.resetVehicleModels}
              ref={this.formStep}
              formStep={formSteps[currentStepIndex]}
              currentStepIndex={currentStepIndex}
              config={config}
              person={person}
              existedName={existedName}
              existedNamesArray={existedNamesArray}
              formConfig={formConfig}
              vehicleBrands={vehicleBrands}
              vehicleModels={vehicleModels}
              setPerson={this.setPerson}
            />
            <div className={"button-container"}>
              <button
                className={"next"}
                onClick={currentStepIndex === formSteps.length - 1 ? this.submit : this.shouldGoNextStep}
                style={{
                  backgroundImage: config.submitButtonBackground
                    ? `url(${config.submitButtonBackground.contentUrl})`
                    : 'none',
                  backgroundColor: style.btnBgColor ? style.btnBgColor : 'transparent',
                  borderColor: style.btnBgColor,
                  color: style.btnTextColor
                }}
              >
                {currentStepIndex === formSteps.length - 1 ? formConfig.submitButtonLabel : "Suivant"}
              </button>
            </div>
            {formConfig.legalNoticesEnabled &&
            <div className="legal-notices-button-container">
              <button className="legal-notices-button"
                      dangerouslySetInnerHTML={{ __html: formConfig.legalNoticesLabel }}
                      onClick={() => this.setState({ showLegalNotices: !showLegalNotices })}/>
            </div>
            }
          </div>
        )}
        {(formConfig.legalNoticesEnabled && showLegalNotices) &&
        <div className="legal-notices-wrapper">
          <div className="legal-notices-content"
               dangerouslySetInnerHTML={{ __html: formConfig.legalNoticesContent }}/>
          <button className="notices-close" onClick={() => this.setState({ showLegalNotices: !showLegalNotices })}>X
          </button>
        </div>
        }
      </div>
    );
  }
}

export default Form;
