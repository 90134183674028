import React, { Component, Fragment } from "react";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  //timeout: 1000,
  headers: {'Authorization': `apikey ${process.env.REACT_APP_API_KEY}`}
});

export class TryMyChance extends Component {
  disableGiftChoice = false;
  state = {
    wonGain: null,
    gain: null,
    gift: null,
    isGameFinished: false,
    wonGainIri: null,
    isGainForm: false,
    gainForm: null,
    gainFormItems: null,
    data: {},
    message: null,
    noGainAvialable: false
  };

  tryMyChance = (person, device) => {
    axiosInstance({
      method: "post",
      url: "/try_my_luck",
      headers: {
        "Content-Type": "application/ld+json"
      },
      data: {
        person: person["@id"],
        device: device["@id"]
      }
    }).then(({ data: wonGain }) => {
      if (this.isPlainObject(wonGain)) {
        this.setState({ wonGain });
        axiosInstance(wonGain.gain["@id"]).then(({ data: gain }) => {
          this.setState({ gain });
          if (gain.gifts.length === 1) {
            this.setState({ isGameFinished: true, gift: gain.gifts[0] });
          }
          if (gain.form != null) {
            this.setState({ isGainForm: true });
            axiosInstance({
              method: "get",
              url: gain.form
            }).then(({ data }) => {
              this.setState({ gainForm: data, gainFormItems: data.formItems });
            });
          }
        });
      } else {
        this.setState({ noGainAvialable: true });
      }
    });
  };
  isPlainObject = obj => {
    return Object.prototype.toString.call(obj) === "[object Object]";
  };

  chooseGift = gift => {
    if (this.disableGiftChoice) return;
    this.disableGiftChoice = true;
    const { wonGain } = this.state;
    axiosInstance({
      method: "put",
      url: wonGain["@id"],
      data: { gift: gift["@id"] },
      headers: {
        "Content-Type": "application/ld+json"
      }
    }).then(({ data: wonGain }) => {
      this.setState({ isGameFinished: true, gift: gift });
    });
  };
  selectCity = e => {
    let { name, value } = e.target;
    let data = {
      // id: wonGain.id,
      // "@id": wonGain["@id"],
      options: {
        [name]: value
      }
    };
    this.setState({ data });
  };
  submitData = () => {
    let { wonGain, data } = this.state;
    axiosInstance({
      method: "put",
      url: wonGain["@id"],
      headers: {
        "Content-Type": "application/ld+json"
      },
      data
    }).then(res => {
      if (res.status === 200) {
        this.setState({
          message: "Merci",
          isGainForm: false
        });
      }
    });
  };
  componentWillReceiveProps(props, state) {
    this.tryMyChance(props.person, props.device);
  }
  close = () => {
    this.props.reset();
  };
  render() {
    const {
      wonGain,
      gain,
      gift,
      isGameFinished,
      isGainForm,
      gainForm,
      gainFormItems,
      message,
      noGainAvialable
    } = this.state;
    const { config } = this.props;
    return (
      <div className="try-my-luck">
        {wonGain !== null &&
          gain !== null &&
          gain.gifts.length > 1 &&
          gift === null && (
            <div className="gifts">
              <div
                style={{
                  background: `url(${config.selectGiftSuccessBg.contentUrl})`
                }}
                className="congrats"
              >
                <div className="titles">
                  <h2 style={{ color: config.titleColor, fontSize: "60px" }}>
                    {config.successTitle}
                  </h2>
                  <h4 style={{ color: config.subTitleColor, fontSize: "30px" }}>
                    {config.selectGiftText}
                  </h4>
                </div>
              </div>
              <div className="gift-wrapper">
                {gain.gifts.map((g, key) => (
                  <Fragment>
                    <div
                      key={g.id}
                      onClick={() => {
                        this.chooseGift(g);
                      }}
                      className="gift"
                    >
                      <img src={g.image.contentUrl} alt="" />
                    </div>
                    {key === 0 && <span className="or">Ou</span>}
                  </Fragment>
                ))}
                <span className="gifts-border" />
              </div>
            </div>
          )}
        {isGameFinished && (
          <div className="gifts">
            <h2 style={{ color: config.titleColor, fontSize: "60px" }}>
              {config.successTitle}
            </h2>
            <h4 style={{ color: config.subTitleColor, fontSize: "30px" }}>
              {config.successSubTitle}
            </h4>
            <h1 style={{ fontSize: "60px" }}>{gift.name}</h1>
            <div
              className="gift-wrapper congrats"
              style={{
                background: `url(${config.successGainBg.contentUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center"
              }}
            >
              <div
                className={
                  isGainForm && gainFormItems !== null
                    ? "gift has-form"
                    : "gift single-gift"
                }
              >
                <img src={gift.image.contentUrl} alt="" />
                <br />
                {isGainForm === false && message !== null && (
                  <div className="gain-success-msg ">{message}</div>
                )}
                {isGainForm && gainFormItems !== null && (
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        fontSize: "40px",
                        margin: "0",
                        textTransform: "uppercase",
                        color: config.titleColor
                      }}
                    >
                      {gainForm.title}
                    </h3>
                    <form noValidate>
                      {gainFormItems.map(formItem => {
                        if (formItem.type === "choice") {
                          return (
                            <select
                              key={formItem.id}
                              name={formItem.name}
                              required={formItem.required}
                              placeholder={formItem.placeHolder}
                              id={formItem.slug}
                              onChange={this.selectCity}
                              className={"form-control"}
                            >
                              {formItem.formChoices.map(choice => {
                                return (
                                  <option value={choice.value} key={choice.id}>
                                    {choice.key}
                                  </option>
                                );
                              })}
                            </select>
                          );
                        } else {
                          return (
                            <input
                              key={formItem.id}
                              id={formItem.slug}
                              type={formItem.type}
                              name={formItem.name}
                              required={formItem.required}
                              disabled={!formItem.enabled}
                              placeholder={formItem.placeHolder}
                              className={"form-control"}
                              style={{
                                width: "400px",
                                height: "60px",
                                borderRadius: config.inputBorderRadius
                              }}
                              minLength={
                                formItem.minLength ? formItem.minLength : ""
                              }
                              maxLength={
                                formItem.maxLength ? formItem.maxLength : ""
                              }
                            />
                          );
                        }
                      })}
                    </form>
                    <br />
                    <button
                      onClick={this.submitData}
                      style={{
                        background: config.buttonBg
                          ? `url(${config.buttonBg.contentUrl})`
                          : config.btnBgColor,
                        backgroundSize: "100%",
                        borderColor: config.btnBgColor,
                        border: "none",
                        color: config.btnTextColor,
                        width: "400px",
                        height: "166px",
                        fontSize: "30px",
                        backgroundRepeat: "no-repeat",
                        display: "inline-block",
                        paddingBottom: "80px"
                      }}
                    >
                      Envoyer
                    </button>
                  </div>
                )}
              </div>
            </div>

            {isGainForm === false && (
              <div className="close-button ">
                <img
                  src={config.closeButtonIcon.contentUrl}
                  alt="close icon"
                  onClick={this.close}
                />
                <p style={{ color: config.closeButtonColor, fontSize: "24px" }}>
                  {config.closeButtonLabel}
                </p>
              </div>
            )}
          </div>
        )}

        {wonGain === null && noGainAvialable && (
          <div style={{ margin: "250px" }}>
            <p style={{ color: "#fff", fontSize: "30px" }}>
              pas de gain disponible pour le moment{" "}
            </p>
            <div className="close-button ">
              <img
                src={config.closeButtonIcon.contentUrl}
                alt="close icon"
                onClick={this.close}
              />
              <p style={{ color: config.closeButtonColor, fontSize: "24px" }}>
                {config.closeButtonLabel}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TryMyChance;
